.container {
    display: flex;
    justify-content: center;
    gap: 1rem;

    margin-top: 2rem;
}

.card {
    font-size: 5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #081c43;
    color: white;

    border-radius: 1rem;

    padding: 1rem 2rem;

    position: relative;
}

.card span {
    position: absolute;

    width: 100%;
    height: 1px;

    background-color: black;
}

@media only screen and (max-width: 640px) {
    .container {
        gap: 0.5rem;
    }

    .card {
        font-size: 2rem;
        padding: 0.5rem 1rem;

        border-radius: 0.5rem;
    }
}